<template>
  <div class="products">
    <h1 class="mb-4">Products</h1>

    <h3>API</h3>
    <div class="mb-5 container text-start">
      <div class="row">
        <div class="offset-1 col-10">
          <div class="product mb-4 p-4">
            <div class="row mb-3">
              <div class="col-9">
                <div class="fw-bolder">Public Limited</div>
                <div class="text-muted">Very limited access to public API</div>
              </div>
              <div class="col-3 text-end">
                <div class="fw-bolder">FREE</div>
                <div class="text-muted">forever</div>
              </div>
            </div>
            <hr>
            <div class="row mb-3">
              <div class="col-3">
                <div class="product-attr">
                  <div class="product-attr-value">5,000</div>
                  <div class="product-attr-label">requests per day</div>
                </div>
              </div>
              <div class="col-3">
                <div class="product-attr">
                  <div class="product-attr-value">1 req / sec</div>
                  <div class="product-attr-label">rate limit</div>
                </div>
              </div>
              <div class="col-3">
                <div class="product-attr">
                  <div class="product-attr-value">YES</div>
                  <div class="product-attr-label">high peak blocks</div>
                </div>
              </div>
            </div>
            <div class="row mb-2 fw-light">
              <div class="col-12">
                Ideal for very small hobby projects not expecting or receiving much traffic.
                Highly rate limited and subject to generalized blocks during high traffic peaks.
              </div>
            </div>
            <hr>
            <div class="row">
              <div class="col-12">
                Visit the <a href="https://docs.teztools.io">API documentation</a> to get started.
              </div>
            </div>
          </div>
          <div class="product mb-4 p-4">
            <div class="row mb-3">
              <div class="col-9">
                <div class="fw-bolder">Public</div>
                <div class="text-muted">Semi-limited access to public API</div>
              </div>
              <div class="col-3 text-end">
                <div class="fw-bolder">1 tez</div>
                <div class="text-muted">per month</div>
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-3">
                <div class="product-attr">
                  <div class="product-attr-value">10,000</div>
                  <div class="product-attr-label">requests per day</div>
                </div>
              </div>
              <div class="col-3">
                <div class="product-attr">
                  <div class="product-attr-value">5 req / sec</div>
                  <div class="product-attr-label">rate limit</div>
                </div>
              </div>
              <div class="col-3">
                <div class="product-attr">
                  <div class="product-attr-value">YES</div>
                  <div class="product-attr-label">high peak blocks</div>
                </div>
              </div>
            </div>
            <div class="row mb-2 fw-light">
              <div class="col-12">
                Ideal for individuals or small projects receiving limited traffic. Rate limited
                with risk of being temporarily blocked during high peak traffic.
              </div>
            </div>
            <hr>
            <div class="row">
              <div class="col-12">
                Email <a href="mailto:contact@teztools.io">contact@teztools.io</a> or Manitcor on Telegram/Discord.
              </div>
            </div>
          </div>
          <div class="product mb-4 p-4">
            <div class="row mb-3">
              <div class="col-9">
                <div class="fw-bolder">API Medium</div>
                <div class="text-muted">No rate limit access to public API</div>
              </div>
              <div class="col-3 text-end">
                <div class="fw-bolder">10 tez</div>
                <div class="text-muted">per month</div>
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-3">
                <div class="product-attr">
                  <div class="product-attr-value">150,000</div>
                  <div class="product-attr-label">requests per day</div>
                </div>
              </div>
              <div class="col-3">
                <div class="product-attr">
                  <div class="product-attr-value">NONE</div>
                  <div class="product-attr-label">rate limit</div>
                </div>
              </div>
              <div class="col-3">
                <div class="product-attr">
                  <div class="product-attr-value">NO</div>
                  <div class="product-attr-label">high peak blocks</div>
                </div>
              </div>
            </div>
            <div class="row mb-2 fw-light">
              <div class="col-12">
                Ideal for projects receiving medium traffic. No
                risk of being rate limited or temporarily blocked during high peak traffic.
              </div>
            </div>
            <hr>
            <div class="row">
              <div class="col-12">
                Email <a href="mailto:contact@teztools.io">contact@teztools.io</a> or Manitcor on Telegram/Discord.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <h3>UI</h3>
    <div class="mb-5 container text-start">
      <div class="row">
        <div class="offset-1 col-10">
          <div class="product mb-4 p-4">
            <div class="row mb-3">
              <div class="col-9">
                <div class="fw-bolder">TezTools UI Basic</div>
                <div class="text-muted">Public access</div>
              </div>
              <div class="col-3 text-end">
                <div class="fw-bolder">FREE</div>
                <div class="text-muted">forever</div>
              </div>
            </div>
            <hr>
            <div class="row mb-3">
              <div class="col-3">
                <div class="product-attr">
                  <div class="product-attr-value">LIMITED</div>
                  <div class="product-attr-label">use</div>
                </div>
              </div>
              <div class="col-3">
                <div class="product-attr">
                  <div class="product-attr-value">FREE</div>
                  <div class="product-attr-label">forever</div>
                </div>
              </div>
            </div>
            <div class="row mb-2 fw-light">
              <div class="col-12">
                TezTools UI is a web-based tool showcasing the TezTools API that presents
                chain analytics of Tezos accounts, DeFi tokens/farms, and much more. It is
                currently under active development.
              </div>
            </div>
            <hr>
            <div class="row">
              <div class="col-12">
                Visit:
                <a href="https://teztools.io" target="_blank">Production</a> |
                <a href="https://dev.teztools.io" target="_blank">Development</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <h3>Nodes</h3>
    <div class="container text-start">
      <div class="row">
        <div class="offset-1 col-10">
          <div class="product mb-4 p-4">
            <div class="row mb-3">
              <div class="col-9">
                <div class="fw-bolder">Public Limited</div>
                <div class="text-muted">Very limited access to public cluster</div>
              </div>
              <div class="col-3 text-end">
                <div class="fw-bolder">FREE</div>
                <div class="text-muted">forever</div>
              </div>
            </div>
            <hr>
            <div class="row mb-2 fw-light">
              <div class="col-12">
                Ideal for very small hobby projects not expecting or receiving much traffic.
                Highly rate limited and subject to generalized blocks during high traffic peaks.
              </div>
            </div>
            <hr>
            <div class="row">
              <div class="col-12">
                Connection details coming soon...
              </div>
            </div>
          </div>
          <div class="product mb-4 p-4">
            <div class="row mb-3">
              <div class="col-9">
                <div class="fw-bolder">Public</div>
                <div class="text-muted">Semi-limited access to public cluster</div>
              </div>
              <div class="col-3 text-end">
                <div class="fw-bolder">1 tez</div>
                <div class="text-muted">per month</div>
              </div>
            </div>
            <hr>
            <div class="row mb-2 fw-light">
              <div class="col-12">
                Ideal for individuals or small projects receiving limited traffic. Some
                rate limiting, but no/low risk of generalized blocks during high
                traffic peaks.
              </div>
            </div>
            <hr>
            <div class="row">
              <div class="col-12">
                Email <a href="mailto:contact@teztools.io">contact@teztools.io</a> or Manitcor on Telegram/Discord.
              </div>
            </div>
          </div>
          <div class="product mb-4 p-4">
            <div class="row mb-3">
              <div class="col-9">
                <div class="fw-bolder">Shared Node</div>
                <div class="text-muted">Semi-limited access to shared cluster</div>
              </div>
              <div class="col-3 text-end">
                <div class="fw-bolder">5 tez</div>
                <div class="text-muted">per month</div>
              </div>
            </div>
            <hr>
            <div class="row mb-2 fw-light">
              <div class="col-12">
                Ideal for small projects receiving limited traffic wanting better performance.
                Some rate limiting, but no/low risk of generalized blocks during high traffic peaks.
              </div>
            </div>
            <hr>
            <div class="row">
              <div class="col-12">
                Email <a href="mailto:contact@teztools.io">contact@teztools.io</a> or Manitcor on Telegram/Discord.
              </div>
            </div>
          </div>
          <div class="product mb-4 p-4">
            <div class="row mb-3">
              <div class="col-9">
                <div class="fw-bolder">Dedicated Node</div>
                <div class="text-muted">Full access to a dedicated node</div>
              </div>
              <div class="col-3 text-end">
                <div class="fw-bolder">60 tez</div>
                <div class="text-muted">per month</div>
              </div>
            </div>
            <hr>
            <div class="row mb-2 fw-light">
              <div class="col-12">
                Ideal for small-to-medium sized projects with growing traffic and a desire
                to not rely on public or shared infrastructure. No rate limiting and no
                risk of generalized blocks during high traffic peaks.
              </div>
            </div>
            <hr>
            <div class="row">
              <div class="col-12">
                Email <a href="mailto:contact@teztools.io">contact@teztools.io</a> or Manitcor on Telegram/Discord.
              </div>
            </div>
          </div>
          <div class="product mb-4 p-4">
            <div class="row mb-3">
              <div class="col-9">
                <div class="fw-bolder">Dedicated Standard Cluster</div>
                <div class="text-muted">Full access to 2 dedicated nodes w/ load balancer</div>
              </div>
              <div class="col-3 text-end">
                <div class="fw-bolder">120 tez</div>
                <div class="text-muted">per month</div>
              </div>
            </div>
            <hr>
            <div class="row mb-2 fw-light">
              <div class="col-12">
                Ideal for medium sized projects with growing traffic, a desire
                to not rely on public or shared infrastructure, and some redundancy in
                case one node goes down. No rate limiting and no
                risk of generalized blocks during high traffic peaks.
              </div>
            </div>
            <hr>
            <div class="row">
              <div class="col-12">
                Email <a href="mailto:contact@teztools.io">contact@teztools.io</a> or Manitcor on Telegram/Discord.
              </div>
            </div>
          </div>
          <div class="product mb-4 p-4">
            <div class="row mb-3">
              <div class="col-9">
                <div class="fw-bolder">Dedicated Small Cluster</div>
                <div class="text-muted">Full access to 3 dedicated nodes w/ load balancer</div>
              </div>
              <div class="col-3 text-end">
                <div class="fw-bolder">190 tez</div>
                <div class="text-muted">per month</div>
              </div>
            </div>
            <hr>
            <div class="row mb-2 fw-light">
              <div class="col-12">
                Ideal for medium sized projects with growing traffic, a desire
                to not rely on public or shared infrastructure, and some redundancy in
                case one node goes down. No rate limiting and no
                risk of generalized blocks during high traffic peaks.
              </div>
            </div>
            <hr>
            <div class="row">
              <div class="col-12">
                Email <a href="mailto:contact@teztools.io">contact@teztools.io</a> or Manitcor on Telegram/Discord.
              </div>
            </div>
          </div>
          <div class="product mb-4 p-4">
            <div class="row mb-3">
              <div class="col-9">
                <div class="fw-bolder">Dedicated Medium Cluster</div>
                <div class="text-muted">Full access to 4 dedicated nodes w/ load balancer</div>
              </div>
              <div class="col-3 text-end">
                <div class="fw-bolder">260 tez</div>
                <div class="text-muted">per month</div>
              </div>
            </div>
            <hr>
            <div class="row mb-2 fw-light">
              <div class="col-12">
                Ideal for medium sized projects with growing traffic, a desire
                to not rely on public or shared infrastructure, and some redundancy in
                case one node goes down. No rate limiting and no
                risk of generalized blocks during high traffic peaks.
              </div>
            </div>
            <hr>
            <div class="row">
              <div class="col-12">
                Email <a href="mailto:contact@teztools.io">contact@teztools.io</a> or Manitcor on Telegram/Discord.
              </div>
            </div>
          </div>
          <div class="product mb-4 p-4">
            <div class="row mb-3">
              <div class="col-9">
                <div class="fw-bolder">Dedicated Large Cluster</div>
                <div class="text-muted">Full access to 6 dedicated nodes w/ load balancer</div>
              </div>
              <div class="col-3 text-end">
                <div class="fw-bolder">500 tez</div>
                <div class="text-muted">per month</div>
              </div>
            </div>
            <hr>
            <div class="row mb-2 fw-light">
              <div class="col-12">
                Ideal for medium sized projects with growing traffic, a desire
                to not rely on public or shared infrastructure, and some redundancy in
                case one node goes down. No rate limiting and no
                risk of generalized blocks during high traffic peaks.
              </div>
            </div>
            <hr>
            <div class="row">
              <div class="col-12">
                Email <a href="mailto:contact@teztools.io">contact@teztools.io</a> or Manitcor on Telegram/Discord.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';

@Options({
  components: {
  },
})
export default class Products extends Vue {}
</script>

<style scoped>

.product {
  background-color: #ffffff;
  border: 1px solid #e2f1ff;
}

.product-attr {
  background-color: #e2f1ff;
  border: 1px solid #e2f1ff;
  border-radius: 10px;
  padding: 10px;
  text-align: center;
}

.product-attr-value {
  font-size: 1.2rem;
}

.product-attr-label {
  color: #74b8f8;
}

</style>